
import { defineComponent } from "vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import { LoadPanel } from "@/core/composite/composite";

export default defineComponent({
  name: "ActivityViewCard",
  components: {DateTimeFormat},
  props: {
    activity: {}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
