
import {computed, defineComponent, onMounted, ref} from "vue";
import Entities from "@/components/base/common/Entities.vue";
import {
  CaseManagerSelect,
  CompanySelect,
  CoordinatorSelect,
  ExaminerSelect,
  LoadFilterObjects
} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import Cards from "@/components/base/common/Cards.vue";
import ActivityViewCard from "@/views/activity/ActivityViewCard.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "Search",
  components: {BaseSelect, ActivityViewCard, Cards, Entities},
  props: {
    entity: {type: String},
  },
  setup: function (props) {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Pending Activities',
        [
          {link: false, router: '', text: 'Pending Activities'}
        ]
      )
    })
    const filterObject = ref({
      clientId: '',
      coordinatorId: '',
      examinerId: '',
      managerId: '',
      date: '',
      status: 'PENDING',
      entity: props.entity
    });
    const page = computed(() => store.state.ActivityModule.searchPage);
    return {
      filterObject,
      ...CompanySelect(),
      ...LoadFilterObjects(Actions.ACTIVITY_SEARCH_PAGE, filterObject.value, ['user', 'claim', 'caze']),
      page,
      ...CoordinatorSelect(),
      ...CaseManagerSelect(),
      ...ExaminerSelect(),
    }
  },
  methods: {
    onFilter() {
      this.updateFilterObject(this.filterObject);
    },
    onDiscard() {
      this.filterObject = {
        clientId: '',
        coordinatorId: '',
        examinerId: '',
        managerId: '',
        date: '',
        status: 'PENDING',
        entity: this.entity
      }
      this.updateFilterObject(this.filterObject);
    }
  },
  watch: {
    entity(cb) {
      this.filterObject.entity = cb;
      this.updateFilterObject(this.filterObject);
    }
  }

})
